import React, { useEffect, useState } from "react";
import CustomTable from "./table";
import PropertyModel from "./PropertyMadel";
const Table = () => {
  const [properties, setProperties] = useState([]); // Ensure properties starts as an array
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });


  useEffect(() => {
    fetch("https://yarcobr-backend.onrender.com/api/properties") // Backend API endpoint
      .then((response) => response.json())
      .then((data) => {
        // Ensure data is an array before proceeding
        let arr = Array.isArray(data) ? data : [data];
        setProperties(arr);
        console.log(arr);
      })
      .catch((error) => console.error("Error fetching properties:", error));
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.toLowerCase());
  };

  const handleFilterChange = (e) => {
    console.log("filter", e);
    setFilterStatus(e);
  };

  const filteredProperties = properties.filter((property) => {
    const matchesSearch =
      searchQuery === "" ||
      Object.values(property).some((value) =>
        String(value).toLowerCase().includes(searchQuery)
      );
    const matchesFilter =
      filterStatus === "" || property.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  const openDetailModal = (property) => {
    setSelectedProperty(property);
    setShowModal(true);
  };

  const closeDetailModal = () => {
    setShowModal(false);
    setSelectedProperty(null);
  };



  const renderTable = (status, isRent = false) => {
    const statusFiltered = filteredProperties.filter(
      (property) => property.status === status
    );
    const columns = [
      {
        title: "BUILD. #",
        dataIndex: "building_number",
        key: "building_number",
        type: "number",
      },
      {
        title: "UNIT #",
        dataIndex: "unit_number",
        key: "unit_number",
        type: "number",
      },
      {
        title: "BE",
        dataIndex: "bedrooms",
        key: "bedrooms",
        type: "number",
      },
      {
        title: "BA",
        dataIndex: "bathrooms",
        key: "bathrooms",
        type: "number",
      },
      {
        title: "SF",
        dataIndex: "square_feet",
        key: "square_feet",
        type: "number",
      },
      {
        title: "VIEW",
        dataIndex: "view",
        key: "view",
        type: "string",
      },
      {
        title: "UNIT TYPE",
        dataIndex: "unit_type",
        key: "unit_type",
        type: "string",
      },
      ...(isRent
        ? [{ title: 'LEASE TYPE', key: 'lease_type', dataIndex: 'lease_type', type: 'string' }]
        : []),
      {
        title: isRent ? 'RENT AMOUNT' : 'PRICE',
        key: 'price_or_rent',
        dataIndex: 'price_or_rent',
        type: "number",
      },
    ]
    return (
      <div className="mb-12 relative">
        <h3 className="bg-red-600 text-white font-bold text-lg p-2">
          {status.toUpperCase()} IN YARCOBR
        </h3>
           <CustomTable columns={columns} dataSource={statusFiltered} onClickRow={(property) => openDetailModal(property)}/>
      </div>
    );
  };

  const renderDetailModal = () => {
    if (!selectedProperty) return null;

    const details = [
      { label: "ID", id: selectedProperty.id },
      { label: "Building Number", value: selectedProperty.building_number },
      { label: "Unit Number", value: selectedProperty.unit_number },
      { label: "Bedrooms", value: selectedProperty.bedrooms },
      { label: "Bathrooms", value: selectedProperty.bathrooms },
      { label: "Square Feet", value: selectedProperty.square_feet },
      { label: "View", value: selectedProperty.view },
      { label: "Unit Type", value: selectedProperty.unit_type },
      { label: "Status", value: selectedProperty.status },
      { label: "Price / Rent", value: selectedProperty.price_or_rent },
      { label: "Lease Type", value: selectedProperty.lease_type },
      { label: "Coordinates", value: selectedProperty.coordinates },
      { label: "Street Number", value: selectedProperty.street_number },
      { label: "Compass Direction", value: selectedProperty.compass_direction },
      { label: "Street Name", value: selectedProperty.street_name },
      {
        label: "Post Street Direction",
        value: selectedProperty.post_street_direction,
      },
      { label: "City", value: selectedProperty.city },
      { label: "State", value: selectedProperty.state },
      { label: "Zip Code", value: selectedProperty.zip_code },
      { label: "Zip Plus Four", value: selectedProperty.zip_plus_four },
      { label: "County", value: selectedProperty.county },
      { label: "Real Estate Taxes", value: selectedProperty.real_estate_taxes },
      {
        label: "Real Estate Tax Year",
        value: selectedProperty.real_estate_tax_year,
      },
      { label: "Front Door Facing", value: selectedProperty.front_door_facing },
      { label: "Home Type", value: selectedProperty.home_type },
      { label: "Lot Size", value: selectedProperty.lot_size },
      {
        label: "Garage Parking Type",
        value: selectedProperty.garage_parking_type,
      },
      { label: "Garage Size", value: selectedProperty.garage_size },
      {
        label: "Driveway Parking Spaces",
        value: selectedProperty.driveway_parking_spaces,
      },
      {
        label: "Total Square Footage",
        value: selectedProperty.total_square_footage,
      },
      { label: "Year Built", value: selectedProperty.year_built },
      {
        label: "Stories Inside Home",
        value: selectedProperty.stories_inside_home,
      },
      {
        label: "Master Bedroom Floor",
        value: selectedProperty.master_bedroom_floor,
      },
      { label: "Pool Type", value: selectedProperty.pool_type },
      { label: "Outdoor Sitting", value: selectedProperty.outdoor_sitting },
      { label: "Private Amenities", value: selectedProperty.private_amenities },
      { label: "Renovated", value: selectedProperty.renovated },
      { label: "Renovation Date", value: selectedProperty.renovation_date },
      { label: "Waterfront", value: selectedProperty.waterfront },
      { label: "Waterfront Type", value: selectedProperty.waterfront_type },
      {
        label: "Waterfront Frontage",
        value: selectedProperty.waterfront_frontage,
      },
      { label: "Boat Access", value: selectedProperty.boat_access },
      { label: "Dock Type", value: selectedProperty.dock_type },
      { label: "View Type", value: selectedProperty.view_type },
      { label: "Renovation Type", value: selectedProperty.renovation_type },
      { label: "Floor Type", value: selectedProperty.floor_type },
      { label: "Roof Type", value: selectedProperty.roof_type },
      { label: "Roof Age", value: selectedProperty.roof_age },
      {
        label: "Air Conditioning Type",
        value: selectedProperty.air_conditioning_type,
      },
      { label: "Construction Type", value: selectedProperty.construction_type },
      { label: "Additional Rooms", value: selectedProperty.additional_rooms },
      { label: "Interior Features", value: selectedProperty.interior_features },
      {
        label: "Home Exterior Features",
        value: selectedProperty.home_exterior_features,
      },
      { label: "Appliances", value: selectedProperty.appliances },
    ];

    return (
      // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-50">
      //   <div className="bg-white w-full max-w-2xl max-h-[90vh] overflow-y-auto rounded p-4">
      //     <div className="flex justify-between items-center mb-4">
      //       <h2 className="text-xl font-bold">Property Details</h2>
      //       <button
      //         className="text-red-600 font-bold text-lg"
      //         onClick={closeDetailModal}
      //       >
      //         ✕
      //       </button>
      //     </div>

      //     <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      //       {details.map((detail) =>
      //         detail.value !== null &&
      //           detail.value !== undefined &&
      //           detail.value !== "" ? (
      //           <div key={detail.label} className="border p-2 rounded">
      //             <div className="font-bold text-gray-700">{detail.label}:</div>
      //             <div className="text-gray-900 break-words">
      //               {detail.value}
      //             </div>
      //           </div>
      //         ) : null
      //       )}
      //     </div>
      //   </div>
      // </div>
      <>
      <PropertyModel data={details} onClose={()=>closeDetailModal()}/>
      </>
    );
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold text-center text-red-600 mb-2">
        YACHT AND RACQUET CLUB OF BOCA RATON{" "}
      </h2>
      <p className="text-center text-gray-700 mb-6">
        A luxury resort-style community with private marina and private beach
      </p>

      {renderTable("For Sale")}
      {renderTable("For Rent", true)}
      {renderTable("Sold")}
      {renderTable("Rented", true)}


      {showModal && selectedProperty && renderDetailModal()}
    </div>
  );
};

export default Table;
