import axiosInstance from './utils/trestleaxiosInstance';
import axios from "axios";


async function getAccessToken() {
    try {
        const response = await axiosInstance.post('/api/trestle/authenticate');
        return response.data.accessToken;
    } catch (error) {
        console.error('Error fetching access token:', error.message);
        throw error;
    }
}


async function fetchProperty(listingKey) {
    try {
   
        const token = await getAccessToken();

        const propertyApi = axios.create({
            baseURL: 'https://api-trestle.corelogic.com/trestle',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const response = await propertyApi.get('/odata/Property', {
            params: {
                $filter: `ListingKey eq '${listingKey}'`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching property:', error.message);
        throw error;
    }
}

export { getAccessToken, fetchProperty };
