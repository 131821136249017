import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  LayersControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import * as turf from "@turf/turf";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapSearch = () => {
  const [filters, setFilters] = useState({
    status: "",
    propertyType: "",
    minPrice: "",
    maxPrice: "",
  });
  const [properties, setProperties] = useState([]);
  const [polygon, setPolygon] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch("https://yarcobr-backend.onrender.com/api/properties");
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    fetchProperties();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const isPointInPolygon = (point, polyCoords) => {
    const pointGeo = turf.point(point);
    const polyGeo = turf.polygon([polyCoords]);
    return turf.booleanPointInPolygon(pointGeo, polyGeo);
  };

  const filteredProperties = properties.filter((property) => {
    const price = parseFloat(property.price_or_rent);
    const minPrice = parseFloat(filters.minPrice);
    const maxPrice = parseFloat(filters.maxPrice);

    const matchesFilters =
      (!filters.status || property.status === filters.status) &&
      (!filters.propertyType || property.unit_type === filters.propertyType) &&
      (isNaN(minPrice) || price >= minPrice) &&
      (isNaN(maxPrice) || price <= maxPrice);

    if (polygon && property.coordinates) {
      const [latitude, longitude] = property.coordinates
        .split(",")
        .map((coord) => parseFloat(coord.trim()));
      if (isNaN(latitude) || isNaN(longitude)) return false;

      return matchesFilters && isPointInPolygon([longitude, latitude], polygon);
    }

    return matchesFilters;
  });

  const onCreated = (e) => {
    if (e.layerType === "polygon") {
      let latLngs = e.layer.getLatLngs()[0].map((latLng) => [latLng.lng, latLng.lat]);

      if (
        latLngs.length > 0 &&
        (latLngs[0][0] !== latLngs[latLngs.length - 1][0] ||
          latLngs[0][1] !== latLngs[latLngs.length - 1][1])
      ) {
        latLngs.push(latLngs[0]);
      }

      const bounds = L.latLngBounds(latLngs.map(([lng, lat]) => [lat, lng]));

      const map = mapRef.current;
      if (map) {
        map.fitBounds(bounds);
      }

      setPolygon(latLngs);
      e.layer.remove();
    }
  };

  const onDeleted = () => {
    setPolygon(null);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3 p-4">
        <h2 className="text-xl font-bold mb-4">Filters</h2>
        <div className="space-y-4">
          <select
            name="status"
            onChange={handleInputChange}
            className="w-full border px-3 py-2 rounded"
          >
            <option value="">Status</option>
            <option value="For Sale">For Sale</option>
            <option value="For Rent">For Rent</option>
          </select>
          <select
            name="propertyType"
            onChange={handleInputChange}
            className="w-full border px-3 py-2 rounded"
          >
            <option value="">Property Type</option>
            <option value="Condo">Condo</option>
            <option value="Townhouse">Townhouse</option>
            <option value="TH">TH</option>
          </select>
          <input
            type="number"
            name="minPrice"
            placeholder="Min Price"
            onChange={handleInputChange}
            className="w-full border px-3 py-2 rounded"
          />
          <input
            type="number"
            name="maxPrice"
            placeholder="Max Price"
            onChange={handleInputChange}
            className="w-full border px-3 py-2 rounded"
          />
        </div>
      </div>

      <div className="w-full md:w-2/3 h-[500px]">
        <MapContainer
          center={[26.3684, -80.0705]}
          zoom={12}
          className="h-full"
          ref={mapRef}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Street Map">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                url='https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVsZXNoYiIsImEiOiJjbTE1MTk0ZjMwMTE1MmlxenkwbWp3ZmR5In0.VxGJgMZltQ0t7J0D5w6HiA'
                
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Terrain">
              <TileLayer
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={onCreated}
              onDeleted={onDeleted}
              draw={{ rectangle: false, circle: false, marker: false, polyline: false }}
            />
          </FeatureGroup>
          {filteredProperties.map((property) => {
            if (!property.coordinates) return null;
            const [latitude, longitude] = property.coordinates
              .split(",")
              .map((coord) => parseFloat(coord.trim()));
            if (isNaN(latitude) || isNaN(longitude)) return null;

            return (
              <Marker key={property.id} position={[latitude, longitude]}>
                <Popup>
                  <h3>Unit: {property.unit_number}</h3>
                  <p>Price: ${parseFloat(property.price_or_rent).toLocaleString()}</p>
                  <p>Bedrooms: {property.bedrooms}</p>
                  <p>Bathrooms: {property.bathrooms}</p>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    </div>
  );
};

export default MapSearch;
