import Logo from "./assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-[#BA1500] text-white text-sm font-semibold">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between gap-6">
          <div className="justify-items-center">
            <h4 className="font-bold text-lg">American Realty Network LLC.</h4>
            <img src={Logo} alt="Logo" className="h-16 w-16 mr-4" />
            <p className="mt-2">433 Plaza Real, Suite 275</p>
            <p>Boca Raton, Florida, USA 33433</p>
          </div>
          <div className="justify-items-center">
            <p className="font-semibold">Contact Information: </p>
            <p className="mt-2">
              <span className="font-semibold">Phone:</span> +1 561-481-8131
            </p>
            <p>
              <span className="font-semibold">Email:</span>{" "}
              <a
                href="mailto:miami23@EricNur.com"
                className="hover:underline font-semibold"
              >
                miami23@EricNur.com
              </a>
            </p>
          </div>

          <div className="text-center md:text-right justify-items-center">
            <Link to="/" className="hover:underline block">
              HOME
            </Link>
            <a href="#about-us" className="hover:underline block">
              ABOUT US
            </a>
            <a href="#eric-nur" className="hover:underline block">
              ERIC NUR
            </a>
            <a href="#contact" className="hover:underline  block">
              CONTACT US
            </a>
            <Link to="/mapsearch" className="hover:underline  block">
              MAP SEARCH PAGE
            </Link>
            <Link to="/termsAndConditions" className="hover:underline block">
              TERMS OF USE
            </Link>
            <Link to="/privacypolicy" className="hover:underline block">
              PRIVACY POLICY
            </Link>
          </div>
        </div>

        <div className="border-t border-white mt-4 pt-4 text-center text-xs">
          <p>
            Information obtained from MLS, public records, and other sources is
            believed to be correct but not warranted. This is not the official
            website of YARCOBR.
          </p>
          <p className="mt-2">
            &copy; {currentYear} American Realty Network LLC. All Rights
            Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
