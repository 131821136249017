import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import { Popover } from "flowbite-react";
import { URL } from "../utils/axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from 'react-share';
import MapComponent from "./MapComponent";
import Header from "./Header";
import Footer from "./Footer";
import axiosInstance from "../utils/axios";
import { FormattedNumber } from "react-intl";
const PropertyDetails = () => {
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [profile, setProfile] = useState([]);
  const [propertyImage, setPropertyImage] = useState([]);
  const [propertyDetials, setPropertyDetials] = useState([]);
  const [propertyAddress, setPropertyAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState();
  const [price, setPrice] = useState();
  const [beds, setBeds] = useState();
  const [status, setStatus] = useState();
  const [propertyTitle, setPropertyTitile] = useState();

  const thumbnailRef = useRef(null);

  const shareUrl = `${URL}${window.location.pathname}`; // The URL to be shared
  const title = "Check out this amazing property!";

  const scrollThumbnails = (index) => {
    const thumbnailList = thumbnailRef.current;
    if (!thumbnailList) return;

    const thumbnailWidth = thumbnailList?.firstChild?.offsetWidth || 64; // Thumbnail width + margin
    const offset = index * thumbnailWidth; // Scroll position based on the selected thumbnail

    thumbnailList.scrollTo({
      left: offset,
      behavior: "smooth",
    });
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % propertyImage?.length;
      scrollThumbnails(newIndex); // Scroll the selected thumbnail into view
      return newIndex;
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex === 0 ? propertyImage?.length - 1 : prevIndex - 1;
      scrollThumbnails(newIndex); // Scroll the selected thumbnail into view
      return newIndex;
    });
  };

  const showImage = (index) => {
    setCurrentIndex(index);
    scrollThumbnails(index); // Scroll the selected thumbnail into view
  };
  // Auto slideshow logic
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  //     }, 5000); // Change every 3 seconds

  //     return () => clearInterval(interval); // Cleanup interval on unmount
  //   }, [images.length]);
  useEffect(() => {
    const handleImageProperty = async () => {
      await axiosInstance
        .get(`/api/images/${id}`)
        .then((response) => {
          setPropertyImage(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });

      await axiosInstance
        .get(`/api/properties/${id}`)
        .then((response) => {
          setPropertyDetials([
            { label: "ID", value: response.data[0].id },
            {
              label: "Building Number",
              value: response.data[0].building_number,
            },
            { label: "Unit Number", value: response.data[0].unit_number },
            { label: "Bedrooms", value: response.data[0].bedrooms },
            { label: "Bathrooms", value: response.data[0].bathrooms },
            { label: "Square Feet", value: response.data[0].square_feet },
            { label: "View", value: response.data[0].view },
            { label: "Unit Type", value: response.data[0].unit_type },
            { label: "Status", value: response.data[0].status },
            { label: "Price / Rent", value: response.data[0].price_or_rent },
            { label: "Lease Type", value: response.data[0].lease_type },
            { label: "Coordinates", value: response.data[0].coordinates },

            {
              label: "Real Estate Taxes",
              value: response.data[0].real_estate_taxes,
            },
            {
              label: "Real Estate Tax Year",
              value: response.data[0].real_estate_tax_year,
            },
            {
              label: "Front Door Facing",
              value: response.data[0].front_door_facing,
            },
            { label: "Home Type", value: response.data[0].home_type },
            { label: "Lot Size", value: response.data[0].lot_size },
            {
              label: "Garage Parking Type",
              value: response.data[0].garage_parking_type,
            },
            { label: "Garage Size", value: response.data[0].garage_size },
            {
              label: "Driveway Parking Spaces",
              value: response.data[0].driveway_parking_spaces,
            },
            {
              label: "Total Square Footage",
              value: response.data[0].total_square_footage,
            },
            { label: "Year Built", value: response.data[0].year_built },
            {
              label: "Stories Inside Home",
              value: response.data[0].stories_inside_home,
            },
            {
              label: "Master Bedroom Floor",
              value: response.data[0].master_bedroom_floor,
            },
            { label: "Pool Type", value: response.data[0].pool_type },
            {
              label: "Outdoor Sitting",
              value: response.data[0].outdoor_sitting,
            },
            {
              label: "Private Amenities",
              value: response.data[0].private_amenities,
            },
            { label: "Renovated", value: response.data[0].renovated },
            {
              label: "Renovation Date",
              value: response.data[0].renovation_date,
            },
            { label: "Waterfront", value: response.data[0].waterfront },
            {
              label: "Waterfront Type",
              value: response.data[0].waterfront_type,
            },
            {
              label: "Waterfront Frontage",
              value: response.data[0].waterfront_frontage,
            },
            { label: "Boat Access", value: response.data[0].boat_access },
            { label: "Dock Type", value: response.data[0].dock_type },
            { label: "View Type", value: response.data[0].view_type },
            {
              label: "Renovation Type",
              value: response.data[0].renovation_type,
            },
            { label: "Floor Type", value: response.data[0].floor_type },
            { label: "Roof Type", value: response.data[0].roof_type },
            { label: "Roof Age", value: response.data[0].roof_age },
            {
              label: "Air Conditioning Type",
              value: response.data[0].air_conditioning_type,
            },
            {
              label: "Construction Type",
              value: response.data[0].construction_type,
            },
            {
              label: "Additional Rooms",
              value: response.data[0].additional_rooms,
            },
            {
              label: "Interior Features",
              value: response.data[0].interior_features,
            },
            {
              label: "Home Exterior Features",
              value: response.data[0].home_exterior_features,
            },
            { label: "Appliances", value: response.data[0].appliances },
          ]);
          setPropertyAddress([
            { label: "Street Number", value: response.data[0].street_number },
            {
              label: "Compass Direction",
              value: response.data[0].compass_direction,
            },
            { label: "Street Name", value: response.data[0].street_name },
            {
              label: "Post Street Direction",
              value: response.data[0].post_street_direction,
            },
            { label: "City", value: response.data[0].city },
            { label: "State", value: response.data[0].state },
            { label: "Zip Code", value: response.data[0].zip_code },
            { label: "Zip Plus Four", value: response.data[0].zip_plus_four },
            { label: "County", value: response.data[0].county },
          ]);
          const coordinates = response.data[0].coordinates
            .split(",")
            .map(Number);
          setPosition(coordinates);
          setPrice(Number(response.data[0].price_or_rent));
          setBeds(Number(response.data[0].bedrooms));
          setStatus(response.data[0].status);
          setPropertyTitile({
            view: response.data[0].view,
            home_type: response.data[0].home_type,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    handleImageProperty();
  }, [id]);

  const content = (
    <div className=" text-sm text-gray-500 dark:text-gray-400">
      <div className=" flex gap-5 px-3 py-2">
        {/* Facebook Share */}
        <FacebookShareButton url={shareUrl} quote={title}>
        <span className="border-r border-r-stone-100 px-2"><Icon className="text-blue-300" icon="iconoir:facebook" width="24" height="24" /></span>
        </FacebookShareButton>

        {/* Twitter Share */}
        <TwitterShareButton url={shareUrl} title={title}>
        <span className="border-r border-r-stone-100 px-2"><Icon className="text-blue-500" icon="si:twitter-line" width="24" height="24" /></span>
        </TwitterShareButton>

        {/* WhatsApp Share */}
        <WhatsappShareButton url={shareUrl} title={title}>
        <span className="px-2"><Icon className="text-green-500" icon="basil:whatsapp-outline" width="24" height="24" /></span>
        </WhatsappShareButton>

        {/* LinkedIn Share */}
        <LinkedinShareButton url={shareUrl} title={title}>
        <span className="border-r border-r-stone-100 px-2"><Icon className="text-green-500" icon="basil:linkedin-outline" width="24" height="24" /></span>
        </LinkedinShareButton>
      </div>
    </div>
  );

  return (
    <div className="App flex flex-col min-h-screen bg-gray-50">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-6 overflow-y-auto">
        <div className="grid lg:grid-cols-3 mx-auto px-4 py-6 lg:gap-10">
          <div className="lg:col-span-2 mb-6">
            <div className="flex justify-between py-2">
              {loading ? (
                <div>loading...</div>
              ) : (
                <div className="">
                  <span className="lg:text-4xl mb-2">
                    {propertyTitle?.view} {propertyTitle?.home_type}
                  </span>
                  <div className="flex gap-1 mt-2">
                    <span className="bg-slate-500 text-white px-3 rounded-sm">
                      Status
                    </span>
                    <span
                      className={`${
                        status?.toLowerCase() === "sold"
                          ? "bg-green-600"
                          : "bg-red-600"
                      } text-white px-3 rounded-sm`}
                    >
                      {status}
                    </span>
                  </div>
                  <div className="flex gap-1 mt-2">
                    <Icon
                      icon="basil:location-outline"
                      width="24"
                      height="24"
                    />
                    <span className="text-sm">
                      {propertyAddress.map((item) => item.value).join(", ")}
                    </span>
                  </div>
                </div>
              )}
              <div>
                <span className="lg:text-4xl">
                  <FormattedNumber
                    value={price}
                    style="currency"
                    currency="USD"
                  />
                </span>
              </div>
            </div>
            <div className="col-span-2 bg-white mb-6">
              <div className="mb-2 relative">
                <img
                  src={`${propertyImage[currentIndex]?.image_url}`}
                  alt={`Slide ${currentIndex + 1}`}
                  className="w-full h-[25rem] lg:h-[30rem] object-cover"
                />
                <div className="flex items-center justify-between absolute top-1/2 w-full px-5">
                  <button
                    onClick={() =>
                      showImage(
                        currentIndex - 1 < 0
                          ? propertyImage?.length - 1
                          : currentIndex - 1
                      )
                    }
                    className="px-3 py-1 bg-red-600 bg-opacity-20 hover:bg-red-600 text-white transition ease-in-out"
                  >
                    <Icon icon="ep:arrow-left-bold" width="12" height="24" />
                  </button>
                  <button
                    onClick={() =>
                      showImage(
                        currentIndex + 1 === propertyImage?.length
                          ? 0
                          : currentIndex + 1
                      )
                    }
                    className="px-3 py-1 bg-red-600 bg-opacity-20 hover:bg-red-600 text-white transition ease-in-out"
                  >
                    <Icon icon="weui:arrow-filled" width="12" height="24" />
                  </button>
                </div>
              </div>
              <div className="relative flex items-center">
                <div
                  ref={thumbnailRef}
                  className="flex gap-1 overflow-x-auto scroll-smooth scrollbar-hidden py-2 px-12 w-full"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {propertyImage?.map((img, index) => (
                    <img
                      key={index}
                      src={`${img?.image_url}`}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => showImage(index)}
                      className={`md:w-16 md:h-16 w-12 h-12 object-cover cursor-pointer rounded-lg border-2 transition ${
                        currentIndex === index
                          ? "border-red-500"
                          : "border-transparent"
                      }`}
                    />
                  ))}
                </div>
                {/* Right Scroll Button */}
                <div
                  className={`absolute flex justify-between w-full h-full ${
                    propertyImage?.length >= 10 ? "block" : "hidden"
                  }`}
                >
                  <button
                    className={`px-4 py-2 w-20 shadow-inner bg-opacity-20 transition ease-in-out`}
                    onClick={prevImage}
                  >
                    <Icon icon="ep:arrow-left-bold" width="12" height="24" />
                  </button>
                  <button
                    className="px-4 py-2 w-20 bg-opacity-20 transition ease-in-out "
                    onClick={nextImage}
                  >
                    <Icon icon="weui:arrow-filled" width="12" height="24" />
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white px-6 py-4 mb-6">
              <div className="py-2 border-b-2 mb-4">
                <h2>Overview</h2>
              </div>
              <div className="grid md:grid-cols-2 grid-flow-row gap-4">
                {loading ? (
                  <div>loading....</div>
                ) : (
                  propertyDetials.length >= 0 &&
                  propertyDetials.slice(1, 7).map((property, index) => (
                    <div key={index} className="flex gap-2 w-56">
                      <div className="font-bold text-gray-800">
                        {property.label}:
                      </div>
                      <div className="text-gray-900">{property.value}</div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="bg-white p-6 mb-6">
              <div className="py-2 border-b-2 mb-4">
                <h2>Description</h2>
              </div>
              {loading ? (
                <div className="text-gray-900">loading....</div>
              ) : (
                <div className="text-gray-900">
                  {propertyDetials
                    .slice(14)
                    .map((property) =>
                      property.label && property.value
                        ? `${property.label} ${property.value}`
                        : null
                    )
                    .filter(Boolean)
                    .join(", ")}
                </div>
              )}
            </div>
            <div className="bg-white p-6 mb-6">
              <div className="py-2 border-b-2 mb-4">
                <h2>Address</h2>
              </div>
              <div className="grid lg:grid-cols-2">
                {loading ? (
                  <div>loading...</div>
                ) : (
                  propertyAddress.length >= 0 &&
                  propertyAddress.map((address, index) => (
                    <div key={index} className="flex gap-4">
                      <div className="font-bold text-gray-800">
                        {address.label}:
                      </div>
                      <div className="text-gray-900">{address.value}</div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="bg-white p-6">
              <h2 className="border-b-2 py-2 mb-2">Location</h2>
              <div>
                {position ? (
                  <MapComponent
                    coordinates={position}
                    image={propertyImage}
                    price={price}
                    beds={beds}
                    propertyAddress={propertyAddress}
                  />
                ) : (
                  <div>Loading map...</div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:max-w-lg justify-end ">
            <div className="bg-white px-4 py-5 flex justify-between mb-4">
              <Popover content={content} trigger="hover" arrow={false} placement="top">
                <button className="border py-2 px-6 rounded-md border-green-600 bg-green-50 text-sm text-green-800 font-semibold">
                  <Icon icon="ion:arrow-redo" width="12" height="12" />
                  <span className="ml-2">Share</span>
                </button>
              </Popover>

              <button className="border py-2 px-6 rounded-md border-red-600 bg-red-50 text-sm font-semibold text-red-800">
                <Icon icon="mdi:heart" width="12" height="12" />
                <span className="ml-2">Save</span>
              </button>
            </div>
            <div className="bg-green-700 bg-opacity-50 py-4 px-6 rounded-tr-sm rounded-tl-sm">
              <div className="flex flex-row gap-10">
                <div>
                  {profile.length ? (
                    <img
                      src="https://example.com/profile.jpg"
                      alt="Profile"
                      className="w-20 h-20 object-cover rounded-full"
                    />
                  ) : (
                    <Icon icon="solar:user-bold" width="24" height="24" />
                  )}
                </div>
                <div>
                  <div className="font-bold text-white text-lg">John Doe</div>
                  <div className="text-gray-50 text-sm flex">
                    <Icon
                      icon="solar:phone-outline"
                      width="24"
                      height="24"
                      className="text-yellow"
                    />
                    <span className="ml-1">123-456-7890</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white pb-6 px-6">
              <div>
                <form className="flex flex-col gap-5 py-5">
                  <div className="flex gap-4">
                    <input
                      type="text"
                      className="w-full p-2 round bg-gray-100 focus:ring-red-700 focus:border-red-500"
                      placeholder="Names"
                      required
                    />
                  </div>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      className="w-full p-2 round bg-gray-100 "
                      placeholder="Phone"
                    />
                  </div>
                  <div className="flex gap-4">
                    <input
                      type="email"
                      className="w-full p-2 round bg-gray-100"
                      placeholder="Email"
                    />
                  </div>
                  <div className="flex gap-4">
                    <textarea
                      rows="4"
                      className="w-full p-2 round bg-gray-100"
                      placeholder="Message"
                    />
                  </div>
                  <div className="flex gap-4">
                    <button className="w-full px-6 py-6 rounded-sm border-red-600 bg-red-700 text-sm text-white font-semibold hover:bg-red-500">
                      <Icon icon="ion:send" width="12" height="12" />
                      <span className="ml-2">Submit</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PropertyDetails;
