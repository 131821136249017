import Header from './Header';
import SectionTable from './SectionTable';
import Footer from './Footer';



function Index() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-6">
        <SectionTable  />
      </main>
      <Footer />
    </div>
  );
}

export default  Index;
