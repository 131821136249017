import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { fetchProperty } from './trestlepropertyapi';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import L from "leaflet";

// Custom Marker Icon Fix for Leaflet
const customIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});

// Component that handles click events on the map
const MapClickHandler = ({ setCoordinates }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setCoordinates([lat.toFixed(6), lng.toFixed(6)]);
    },
  });
  return null;
};

// Adds the GeoSearch Control to the map
const SearchField = ({ setCoordinates }) => {
  const map = useMap();

  React.useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      showMarker: true,
      showPopup: true,
      marker: {
        icon: customIcon,
      },
      popupFormat: ({ query, result }) => result.label,
      maxMarkers: 1,
      retainZoomLevel: false,
      animateZoom: true,
      keepResult: false,
    });

    map.addControl(searchControl);

    map.on("geosearch/showlocation", (event) => {
      const { x, y } = event.location; // x = lon, y = lat
      setCoordinates([y.toFixed(6), x.toFixed(6)]);
    });

    return () => map.removeControl(searchControl);
  }, [map, setCoordinates]);

  return null;
};

// Map component that displays the search bar and a marker
const MapWithSearch = ({ coordinates, setCoordinates }) => {
  return (
    <MapContainer
      center={[0, 0]}
      zoom={2}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; OpenStreetMap contributors"
      />
      <SearchField setCoordinates={setCoordinates} />
      <Marker position={coordinates} icon={customIcon}>
        <Popup>Selected Location</Popup>
      </Marker>
      <MapClickHandler setCoordinates={setCoordinates} />
    </MapContainer>
  );
};

// Map Modal for selecting coordinates
const MapModal = ({ setFormData, formData, setMapOpen }) => {
  const [currentCoords, setCurrentCoords] = useState([0, 0]);

  const handleConfirm = () => {
    setFormData({
      ...formData,
      coordinates: currentCoords.join(", "),
    });
    setMapOpen(false);
  };

  const handleClose = () => {
    setMapOpen(false);
  };

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white w-full max-w-3xl h-3/4 relative p-4 rounded flex flex-col">
        {/* Close Icon Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          aria-label="Close Map Selector"
        >
          <Icon icon="mdi:close" width="24" height="24" />
        </button>

        <h3 className="text-lg font-bold mb-2">
          Search and Select Coordinates
        </h3>
        <div className="flex-grow relative">
          <MapWithSearch
            coordinates={currentCoords}
            setCoordinates={setCurrentCoords}
          />
        </div>
        <button
          onClick={handleConfirm}
          className="mt-4 bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

const formSteps = [
  // Basic Information
  [
    "building_number",
    "unit_number",
    "square_feet",
    "view",
    "unit_type",
    "price_or_rent",
    "lease_type",
  ],
  // Address Information
  [
    "street_number",
    "compass_direction",
    "street_name",
    "post_street_direction",
    "city",
    "state",
    "zip_code",
    "zip_plus_four",
    "county",
    "coordinates",
  ],
  // Tax & Property Details
  [
    "real_estate_taxes",
    "real_estate_tax_year",
    "front_door_facing",
    "home_type",
    "lot_size",
    "bedrooms",
    "bathrooms",
    "garage_parking_type",
    "garage_size",
    "driveway_parking_spaces",
    "stories_inside_home",
    "pool_type",
    "outdoor_sitting",
    "private_amenities",
    "total_square_footage",
    "year_built",
    "master_bedroom_floor",
    
  ],
  // Features & Amenities
  [
    "renovated",
    "renovation_date",
    "waterfront",
    "waterfront_type",
    "waterfront_frontage",
    "boat_access",
    "dock_type",
    "view_type",
    "renovation_type",
  ],
  // Construction Details
  [
    "floor_type",
    "roof_type",
    "roof_age",
    "air_conditioning_type",
    "construction_type",
    "additional_rooms",
    "interior_features",
    "home_exterior_features",
    "appliances",
  ],
];

const enumOptions = {
  status: ["Sold", "Rent", "Rented", "Sale"],
  compass_direction: ["None", "E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  post_street_direction: ["None", "E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  county: [
    "Martin County",
    "Palm Beach County",
    "Broward County",
    "Miami-Dade County",
    "Monroe County",
  ],
  front_door_facing: ["E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  home_type: ["SFH", "Townhome", "Villa", "Apartment Unit", "Mobile Home"],
  garage_size: ["0", "1-car", "2-car", "3-car", "4-car or more"],
  driveway_parking_spaces: ["0", "1 space", "2 spaces", "3 spaces or more"],
  stories_inside_home: [
    "1-story home",
    "2-story home",
    "3-story home",
    "Home with 4 story or more",
  ],
  master_bedroom_floor: ["First floor", "Upper floors"],
  renovated: ["YES", "NO"],
  waterfront: ["Yes", "No"],
  boat_access: ["YES", "NO"],
  dock_type: ["Private dock", "Community dock", "No docking"],
  roof_type: [
    "Concrete flat tile roof",
    "Concrete barrel tile roof",
    "Clay barrel tile roof",
    "Composite shingle roof",
    "Wood shingle roof",
    "Flat roof",
    "Metal roof",
    "Fiberglass roof",
    "Bahama roof",
    "Other roof",
  ],
};

const setOptions = {
  garage_parking_type: [
    "Attached covered garage",
    "Detached covered garage",
    "Covered carport",
    "Open driveway parking",
    "Street parking",
  ],
  waterfront_type: ["Lake front","Ocean front","Pond front","Bay front","Lagoon front","On river","On canal","On intracoastal waterways","Other water font"],
  pool_type: [
    "Private indoor pool (not heated)",
    "Private outdoor pool (not heated)",
    "Private indoor heated pool",
    "Private outdoor heated pool",
    "Private jacuzzi/hot tub",
    "Pool is screened in",
    "No private pool",
  ],
  outdoor_sitting: [
    "Open patio",
    "Covered patio",
    "Open balcony",
    "Covered balcony",
    "Covered front porch",
    "Open front porch",
    "No outdoor sitting area",
  ],
  private_amenities: [
    "Private tennis court(s)",
    "Private jacuzzi/hot tub",
    "Private fitness room",
    "Private volleyball court",
    "Private racquetball court",
    "Private basketball court",
    "Private playground",
    "Private barbecue",
    "Private steam room",
    "Private spa",
    "No amenities",
  ],
  view_type: [
    "Garden view",
    "Bay view",
    "Lake view",
    "River view",
    "Canal view",
    "Intracoastal view",
    "Ocean view",
    "Preserve view",
    "Street/Road view",
    "Pool area view",
    "Parking area view",
    "Mountain view",
    "Highway view",
    "Other view",
  ],
  renovation_type: [
    "Fully renovated",
    "Partially renovated",
    "Not Renovated",
    "Kitchen renovated",
    "Master bathroom renovated",
    "Guest bathroom renovated",
    "Living room renovated",
    "Floors replaced",
    "Other",
  ],
  floor_type: [
    "Ceramic tile floors",
    "Porcelain tile floors",
    "Marble floors",
    "Carpeted floors",
    "Hardwood floors",
    "Laminated wood floors",
    "Parquet floors",
    "Concrete floors",
    "Terrazzo floors",
    "Clay floors",
    "Vinyl floors",
    "Other floor",
  ],
  air_conditioning_type: ["Wall unit AC", "Central AC", "Multiple AC units"],
  construction_type: [
    "Aluminum siding",
    "Wood siding",
    "CBS construction",
    "Brick construction",
    "Brick veneer",
    "Pre-cast concrete",
    "Wood frame",
    "Other construction",
  ],
  additional_rooms: [
    "Family room",
    "Den",
    "Utility room",
    "Laundry room",
    "Library/office",
    "Office",
    "Game/media room",
    "Attic",
    "Florida room",
    "Loft",
    "Pantry",
    "Atrium",
    "Maid room",
    "Converted room in garage",
  ],
  interior_features: [
    "Dry bar",
    "Wet bar",
    "Walk in closet(s)",
    "Kitchen island",
    "High ceilings",
    "Elevator",
    "Fireplace",
    "Laundry tub",
    "Pantry",
    "Skylight",
    "Split Bedrooms",
    "Stacked Bedrooms",
    "Impact windows/doors",
    "Other interior features",
    "No interior features",
  ],
  home_exterior_features: [
    "Fence",
    "Wall",
    "Utility shed",
    "Fruit Trees",
    "Solar panels",
    "Stables",
    "Sprinkler system",
    "Other exterior features",
    "No exterior features",
  ],
  appliances: [
    "Refrigerator",
    "Range/Oven",
    "Dishwasher",
    "Microwave",
    "Washer",
    "Dryer",
    "Garbage disposal",
    "Trash compactor",
    "Central vacuum",
    "Intercom",
    "Burglar alarm",
    "Water Softener",
    "Natural gas",
    "Other equipment",
    "No equipment",
  ],
};

const PropertyForm = ({isOpen, onClose}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [mapOpen, setMapOpen] = useState(false);
  const [showMLSModal, setShowMLSModal] = useState(false); // MLS question modal
  const [showMLSInputModal, setShowMLSInputModal] = useState(false); // MLS input modal
  const [showMainModal, setShowMainModal] = useState(false); // Main modal
  const [mlsNumber, setMlsNumber] = useState(""); // MLS number input state

  const [listingKey, setListingKey] = useState('');
  const [property, setProperty] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFetchProperty = async () => {
    setError(null);
    setProperty(null);
    setLoading(true);
    try {
      const apiData = await fetchProperty(listingKey);

  
      const mappedData = {
        building_number: apiData?.NumberOfBuildings || "",
        unit_number: apiData?.UnitNumber || "",
        bedrooms: apiData?.BedroomsTotal || "",
        bathrooms: apiData?.BathroomsTotalInteger || "",
        square_feet: apiData?.LotSizeSquareFeet || "",
        view: apiData?.View ? "Yes" : "No",
        unit_type: apiData?.UnitTypeType || "",
        status: "Sale",
        price_or_rent: apiData?.ListPriceLow || "",
        lease_type: apiData?.ExistingLeaseType ? "Yes" : "No",
        coordinates: apiData?.MapCoordinateSource || "",
        street_number: apiData?.StreetNumber || "",
        compass_direction: apiData?.StreetDirPrefix || "None",
        street_name: apiData?.StreetName || "",
        post_street_direction: apiData?.StreetDirSuffix || "None",
        city: apiData?.City || "",
        state: apiData?.StateOrProvince || "",
        zip_code: apiData?.PostalCode || "",
        zip_plus_four: apiData?.PostalCodePlus4 || "",
        county: apiData?.CountyOrParish || "Miami-Dade County",
        real_estate_taxes: apiData?.TaxAnnualAmount || "",
        real_estate_tax_year: apiData?.TaxYear || "",
        front_door_facing: apiData?.FrontageDirection || "E",
        home_type: apiData?.PropertyType || "",
        lot_size: apiData?.LotSize || "",
        garage_parking_type: apiData?.GarageType?.split(",") || [],
        garage_size: apiData?.GarageSize || "",
        driveway_parking_spaces: apiData?.DrivewaySpaces || "0",
        total_square_footage: apiData?.LivingArea || "",
        year_built: apiData?.YearBuilt || "",
        stories_inside_home: apiData?.Stories || "",
        master_bedroom_floor: apiData?.MasterBedroomLevel || "First floor",
        pool_type: Array.isArray(apiData?.PoolFeatures)
          ? apiData.PoolFeatures
          : apiData?.PoolFeatures?.split(",") || [],

        outdoor_sitting: Array.isArray(apiData?.OutdoorFeatures)
          ? apiData.OutdoorFeatures
          : apiData?.OutdoorFeatures?.split(",") || [],

        private_amenities: Array.isArray(apiData?.Amenities)
          ? apiData.Amenities
          : apiData?.Amenities?.split(",") || [],

        waterfront_type: Array.isArray(apiData?.WaterfrontFeatures)
          ? apiData.WaterfrontFeatures
          : apiData?.WaterfrontFeatures?.split(",") || [],

        view_type: Array.isArray(apiData?.ViewType)
          ? apiData.ViewType
          : apiData?.ViewType?.split(",") || [],

        renovation_type: Array.isArray(apiData?.RenovationType)
          ? apiData.RenovationType
          : apiData?.RenovationType?.split(",") || [],

        floor_type: Array.isArray(apiData?.Flooring)
          ? apiData.Flooring
          : apiData?.Flooring?.split(",") || [],

        air_conditioning_type: Array.isArray(apiData?.Cooling)
          ? apiData.Cooling
          : apiData?.Cooling?.split(",") || [],

        construction_type: Array.isArray(apiData?.ConstructionType)
          ? apiData.ConstructionType
          : apiData?.ConstructionType?.split(",") || [],

        additional_rooms: Array.isArray(apiData?.AdditionalRooms)
          ? apiData.AdditionalRooms
          : apiData?.AdditionalRooms?.split(",") || [],

        interior_features: Array.isArray(apiData?.InteriorFeatures)
          ? apiData.InteriorFeatures
          : apiData?.InteriorFeatures?.split(",") || [],

        home_exterior_features: Array.isArray(apiData?.ExteriorFeatures)
          ? apiData.ExteriorFeatures
          : apiData?.ExteriorFeatures?.split(",") || [],

        appliances: Array.isArray(apiData?.Appliances)
          ? apiData.Appliances
          : apiData?.Appliances?.split(",") || [],

        renovated: apiData?.RenovatedYN ? "YES" : "NO",
        renovation_date: apiData?.RenovationDate || "",
        waterfront: apiData?.WaterfrontYN ? "Yes" : "No",

        waterfront_frontage: apiData?.WaterfrontFrontage || "",
        boat_access: apiData?.BoatAccessYN ? "YES" : "NO",
        dock_type: apiData?.DockType || "No docking",

        roof_type: apiData?.RoofType || "Concrete flat tile roof",
        roof_age: apiData?.RoofAge || "",
      };
  
      setFormData((prevData) => ({ ...prevData, ...mappedData }));
      setShowMLSInputModal(false);
      setShowMainModal(true);
      setLoading(false);
    } catch (err) {
      console.error(err); // Log the error for debugging
      setError("Failed to fetch property details. Please try again.");
      setLoading(false);
      setShowMainModal(false);
    }
  };


  useEffect(() => {
    if (isOpen) {
      setShowMLSModal(true); // Show MLS modal when component opens
      setShowMLSInputModal(false);
      setShowMainModal(false);
    }
  }, [isOpen]);

  const handleMLSModalClose = (hasMLS) => {
    setShowMLSModal(false);
    if (hasMLS) {
      setShowMLSInputModal(true); // Show MLS input modal
    } else {
      setShowMainModal(true); // Skip to main modal
    }
  };

  const handleMLSInputSubmit = () => {
    console.log("Searching for MLS number:", mlsNumber);
    setShowMLSInputModal(false);
    setShowMainModal(true); // Proceed to main modal after search
  };

  const handleMainModalClose = () => {
    setShowMainModal(false);
    onClose(); // Notify parent to close the modal
  };

  const [formData, setFormData] = useState({
    building_number: "",
    unit_number: "",
    bedrooms: "",
    bathrooms: "",
    square_feet: "",
    view: "",
    unit_type: "",
    status: "Rent",
    price_or_rent: "",
    lease_type: "",
    coordinates: "",
    street_number: "",
    compass_direction: "None",
    street_name: "",
    post_street_direction: "None",
    city: "",
    state: "",
    zip_code: "",
    zip_plus_four: "",
    county: "Miami-Dade County",
    real_estate_taxes: "",
    real_estate_tax_year: "",
    front_door_facing: "E",
    home_type: "",
    lot_size: "",
    garage_parking_type: [],
    garage_size: "",
    driveway_parking_spaces: "0",
    total_square_footage: "",
    year_built: "",
    stories_inside_home: "",
    master_bedroom_floor: "First floor",
    pool_type: [],
    outdoor_sitting: [],
    private_amenities: [],
    renovated: "NO",
    renovation_date: "",
    waterfront: "No",
    waterfront_type: [],
    waterfront_frontage: "",
    boat_access: "NO",
    dock_type: "No docking",
    view_type: [],
    renovation_type: [],
    floor_type: [],
    roof_type: "Concrete flat tile roof",
    roof_age: "",
    air_conditioning_type: [],
    construction_type: [],
    additional_rooms: [],
    interior_features: [],
    home_exterior_features: [],
    appliances: [],
  });
  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked
          ? [...(prev[name] || []), value]
          : (prev[name] || []).filter((item) => item !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const renderField = (field) => {
    // Render Lot Size for SFH or Mobile Home
    if (field === "lot_size") {
      if (["SFH", "Mobile Home"].includes(formData.home_type)) {
        return (
          <>
          <label className="block text-sm font-medium capitalize">
          Lot Size
        </label>
          <input
            type="number"
            name="lot_size"
            value={formData.lot_size || ""}
            onChange={handleChange}
            placeholder="Enter lot size in square feet"
            className="mt-1 p-2 block w-full border rounded-md"
          />
          </>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "bedrooms") {
      if (["SFH"].includes(formData.home_type)) {
        return (
          <>
          <label className="block text-sm font-medium capitalize">
          Bed rooms
        </label>
          <input
            type="number"
            name="bedrooms"
            value={formData.bedrooms || ""}
            onChange={handleChange}
            placeholder="Enter bedrooms"
            className="mt-1 p-2 block w-full border rounded-md"
          />
          </>
        );
      }
      return null; // Don't render if condition not met
    }


    if (field === "bathrooms") {
      if (["SFH"].includes(formData.home_type)) {
        return (
          <>
          <label className="block text-sm font-medium capitalize">
          bathrooms
        </label>
          <input
            type="number"
            name="bathrooms"
            value={formData.bathrooms || ""}
            onChange={handleChange}
            placeholder="Enter bathrooms"
            className="mt-1 p-2 block w-full border rounded-md"
          />
          </>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "garage_parking_type") {
      const selectedhome_type = formData.home_type || [];
      const shouldRenderhome_type =
        selectedhome_type.includes("SFH") 
  
      if (shouldRenderhome_type) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          Garage Parking Type
        </label>
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        );
      }
      return null; // Don't render if condition not met
    }
  

    // Render Garage Size if a valid garage type is selected
    if (field === "garage_size") {
      const selectedParking = formData.garage_parking_type || [];
      const shouldRenderGarageSize =
        selectedParking.includes("Attached covered garage") ||
        selectedParking.includes("Detached covered garage");
  
      if (shouldRenderGarageSize) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          Garage Size
        </label>
            <select
              name="garage_size"
              value={formData.garage_size || ""}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border rounded-md"
            >
              <option value="">Select garage size</option>
              {(enumOptions.garage_size || []).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "driveway_parking_spaces") {
      const selectedParking = formData.garage_parking_type || [];
      const shouldRenderGarageSize =
        selectedParking.includes("Open driveway parking");
  
      if (shouldRenderGarageSize) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          Garage Size
        </label>
            <select
              name="driveway_parking_spaces"
              value={formData.driveway_parking_spaces || ""}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border rounded-md"
            >
              <option value="">driveway_parking_spaces</option>
              {(enumOptions.driveway_parking_spaces || []).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "stories_inside_home") {
      if (["SFH", "Townhome", "Villa"].includes(formData.home_type)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          stories inside home
        </label>
            <select
              name="stories_inside_home"
              value={formData.stories_inside_home || ""}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border rounded-md"
            >
              <option value="">stories inside home</option>
              {(enumOptions.stories_inside_home || []).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "pool_type") {
      if (["SFH", "Townhome", "Villa", "Mobile Home"].includes(formData.home_type)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          pool type
        </label>
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "outdoor_sitting") {
      if (["SFH", "Townhome", "Villa", "Mobile Home"].includes(formData.home_type)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          outdoor sitting
        </label>
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        );
      }
      return null; // Don't render if condition not met
    }


    if (field === "private_amenities") {
      if (["SFH", "Townhome", "Villa", "Mobile Home"].includes(formData.home_type)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          private amenities
        </label>
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "master_bedroom_floor") {
      if (["2-story home", "3-story home", "Home with 4 story or more"].includes(formData.stories_inside_home)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          master bedroom floor
        </label>
            <select
              name="master_bedroom_floor"
              value={formData.master_bedroom_floor || ""}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border rounded-md"
            >
              <option value="">master bedroom floor</option>
              {(enumOptions.stories_inside_home || []).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null; // Don't render if condition not met
    }


    if (field === "renovation_date") {
      if (["YES"].includes(formData.renovated)) {
        return (
          <>
          <label className="block text-sm font-medium capitalize">
          renovation date
        </label>
          <input
            type="date"
            name="bedrooms"
            value={formData.renovation_date || ""}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border rounded-md"
          />
          </>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "waterfront_type") {
      if (["Yes"].includes(formData.waterfront)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          waterfront type
        </label>
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        );
      }
      return null; // Don't render if condition not met
    }
    if (field === "waterfront_frontage") {
      if (["Yes"].includes(formData.waterfront)) {
        return (
          <>
          <label className="block text-sm font-medium capitalize">
          Water Font Frontage
        </label>
          <input
            type="test"
            name="bedrooms"
            value={formData.waterfront_frontage || ""}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border rounded-md"
          />
          </>
        );
      }
      return null; // Don't render if condition not met
    }

    if (field === "boat_access") {
      if (["Yes"].includes(formData.waterfront)) {
        return (
          <div className="mt-1">
          <label className="block text-sm font-medium capitalize">
          boat access
        </label>
            <select
              name="master_bedroom_floor"
              value={formData.boat_access || ""}
              onChange={handleChange}
              className="mt-1 p-2 block w-full border rounded-md"
            >
              {(enumOptions.boat_access || []).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }
      return null; // Don't render if condition not met
    }

  
    // Fallback for other fields
    if (enumOptions[field]) {
      return (
        <>
        <label className="block text-sm font-medium capitalize">
        {field.replace(/_/g, " ")}
      </label>
        <select
          name={field}
          value={formData[field] || ""}
          onChange={handleChange}
          className="mt-1 p-2 block w-full border rounded-md"
        >
          <option value="">Select</option>
          {(enumOptions[field] || []).map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        </>
      );
    }

    if (setOptions[field]) {
      return (
        <>
         <label className="block text-sm font-medium capitalize">
        {field.replace(/_/g, " ")}
      </label>
        <div className="mt-1">
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        </>
      );
    }

    if (field === "price_or_rent") {
      return (
        <div className="mt-1">
        <label className="block text-sm font-medium capitalize">
        Price
      </label>
          <input
          type="number"
          name="building_number"
          value={formData[field] || ""}
          className="mt-1 p-2 block w-full border rounded-md"
          onChange={handleChange}
        />
        </div>
      );
  
  }

    if (field === "coordinates") {
      return (
        <div>
        <label className="block text-sm font-medium capitalize">
        {field.replace(/_/g, " ")}
      </label>
          <input
            type="text"
            name="coordinates"
            value={formData[field] || ""}
            readOnly
            className="mt-1 p-2 block w-full border rounded-md"
            placeholder="Click to select coordinates"
            onClick={() => setMapOpen(true)}
          />
          <button
            type="button"
            onClick={() => setMapOpen(true)}
            className="mt-2 bg-blue-600 text-white px-2 py-1 rounded"
          >
            Select on Map
          </button>
        </div>
      );
    }

    if (field === "building_number") {
      return (
        <div>
        <label className="block text-sm font-medium capitalize">
        {field.replace(/_/g, " ")}
      </label>
          <input
            type="number"
            name="building_number"
            value={formData[field] || ""}
            className="mt-1 p-2 block w-full border rounded-md"
            onChange={handleChange}
          />
        </div>
      );
    }

    return (
      <>
      <label className="block text-sm font-medium capitalize">
        {field.replace(/_/g, " ")}
      </label>
      <input
        type="text"
        name={field}
        value={formData[field] || ""}
        onChange={handleChange}
        className="mt-1 p-2 block w-full border rounded-md"
      />
      </>
    );
  };

  const nextStep = () => {
    if (currentStep < formSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 1. Submit property data
      const propertyResponse = await fetch("https://yarcobr-backend.onrender.com/api/properties", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!propertyResponse.ok) {
        throw new Error("Failed to add property");
      }

      const propertyData = await propertyResponse.json();
      const propertyId = propertyData.id; // Assuming the API responds with the new property's ID

      // 2. Upload images
      if (images.length > 0) {
        const formData = new FormData();
        Array.from(images).forEach((file) => formData.append("images", file));

        const imageResponse = await fetch(
          `https://yarcobr-backend.onrender.com/api/images/${propertyId}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error("Failed to upload images");
        }
      }

      alert("Property and images added successfully!");
      setFormData({});
      setImages([]);
      setCurrentStep(0);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the property and images.");
    }
  };

  return (<>
   {/* Map Modal */}
   {mapOpen && (
        <MapModal
          setFormData={setFormData}
          formData={formData}
          setMapOpen={setMapOpen}
        />
      )}

      {/* MLS Question Modal */}
      {isOpen && showMLSModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded-lg shadow-lg p-6 overflow-y-auto">
            <h3 className="text-lg font-bold mb-4">Do you have an MLS number?</h3>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={() => handleMLSModalClose(true)} // Yes button
                className="bg-teal-500 text-white px-4 py-2 rounded"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleMLSModalClose(false)} // No button
                className="bg-red-800 text-white px-4 py-2 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* MLS Input Modal */}
      {isOpen && showMLSInputModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="relative bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded-lg shadow-lg p-6 overflow-y-auto">
            <h3 className="text-lg font-bold mb-4">Enter your MLS number</h3>
            <input
              type="text"
              value={listingKey}
              onChange={(e) => setListingKey(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded mb-4"
              placeholder="MLS Number"
            />
            <div className="flex justify-end">

            <button
                onClick={handleFetchProperty}
                className="bg-teal-500 text-white px-4 py-2 rounded"
                disabled={loading} // Disable button while loading
            >
                {loading ? "Loading..." : "Search"}
            </button>
            {loading && (
                <div className="mt-2 text-teal-500">
                    {/* Example loader */}
                    <svg
                        className="animate-spin h-5 w-5 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                    </svg>
                </div>
            )}
                {error && (
                    <p className="text-red-500 mt-4 text-center">
                        {error}
                    </p>
                )}
            </div>
          </div>
        </div>
      )}

   {isOpen && showMainModal && (
        <div className="fixed inset-0  bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded-lg shadow-lg p-6 h-full max-h-[90vh] overflow-y-auto">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-red hover:text-red-500"
            >
              <Icon icon="bx:x-circle" fontSize={24} />
            </button>
    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-6 bg-white">
    

      <div className="">
        {formSteps[currentStep].map((field) => (
          <div key={field}>
            {renderField(field)}
          </div>
        ))}
      </div>
      <div className="mb-4">
          <label className="block text-sm font-medium">Upload property Images</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="mt-1 block w-full"
          />
        </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          disabled={currentStep === 0}
          className="bg-teal-500 text-white px-4 py-2 rounded"
        >
          Previous
        </button>
        {currentStep === formSteps.length - 1 ? (
          <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md">
            Submit
          </button>
        ) : (
          <button
            type="button"
            onClick={nextStep}
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
          >
            Next
          </button>
        )}
      </div>
    </form>
    </div>
        </div>
      )}


     
      </>
  );
};

export default PropertyForm;
