import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/index';
import Admin from './components/Admin'
import MapSearch from "./components/mapsearchpage";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PropertyDetails from "./components/propertyDetails";
function App() {
  return (
   <Router>
        <div className="font-Poppins bg-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/mapsearch" element={<MapSearch />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/propertyDetails/:id" element={<PropertyDetails />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
