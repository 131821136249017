import axiosInstance from './utils/trestleaxiosInstance';
import axios from 'axios';

async function getAccessToken() {
    try {
        const response = await axiosInstance.post('/api/trestle/authenticate');
        return response.data.accessToken;
    } catch (error) {
        console.error('Error fetching access token:', error.message);
        throw error;
    }
}

async function fetchProperty(listingKey) {
    try {
        const token = await getAccessToken();
        let secondResponse = null;

        const propertyApi = axios.create({
            baseURL: 'https://api-trestle.corelogic.com/trestle',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const response = await propertyApi.get('/odata/Property', {
            params: {
                $filter: `ListingId eq '${listingKey}'`,
            },
        });

        if(!response.data || !response.data.value || response.data.value.length === 0) {
            const secondApi = axios.create({
                baseURL: 'https://api.bridgedataoutput.com/api/v2',
            });
             secondResponse = await secondApi.get(`/OData/miamire/Property`, {
                params: {
                    access_token: `2ea786c97bf14ecce37d2fb7769b1663`,
                    $filter: `ListingId eq '${listingKey}'`,
                },
            });
        }
        
        return response.data.value.length > 0 ? response.data.value[0] : secondResponse.data.value[0]; 
    } catch (error) {
        console.error('Error fetching property:', error.message);
        throw error;
    }
}

async function fetchPropertyMedia(listingKey) {
    try {
        const token = await getAccessToken();
        let images = [];

        try {
            const mediaApi = axios.create({
                baseURL: "https://api-trestle.corelogic.com/trestle",
                headers: { Authorization: `Bearer ${token}` },
                responseType: "arraybuffer",
            });

            const response = await mediaApi.get(`/odata/Property('${listingKey}')/Media/All`);

            if (response.status === 200 && response.data) {
                const contentType = response.headers["content-type"] || "";
                const boundaryMatch = contentType.match(/boundary=(.*)/);
                const boundary = boundaryMatch ? boundaryMatch[1] : null;

                if (boundary) {
                    const data = Buffer.from(response.data, "binary").toString("binary");
                    const parts = data.split(`--${boundary}`).filter(part => part.trim() && part.trim() !== "--");

                    images = parts.map((part, index) => {
                        const [rawHeaders, binaryData] = part.trim().split("\r\n\r\n");
                        if (!binaryData) return null;

                        const headers = {};
                        rawHeaders.split("\r\n").forEach(line => {
                            const [key, value] = line.split(": ");
                            if (key && value) headers[key.trim()] = value.trim();
                        });

                        return {
                            contentId: headers["Content-ID"] || `unknown_${index}`,
                            contentType: headers["Content-Type"] || "image/jpeg",
                            url: `data:${headers["Content-Type"] || "image/jpeg"};base64,${Buffer.from(binaryData, "binary").toString("base64")}`,
                        };
                    }).filter(Boolean);
                } else {
                    console.warn("⚠️ Boundary not found. Skipping binary processing.");
                }
            }
        } catch (error) {
            console.error("Primary API failed:", error.message);
        }

        if (images.length === 0) {
            console.log("🔄 Fetching from secondary API...");
            try {
                const fallbackApi = axios.create({
                    baseURL: "https://api.bridgedataoutput.com/api/v2",
                });

                const response = await fallbackApi.get(`/OData/miamire/Property('${listingKey}')`, {
                    params: { access_token: "2ea786c97bf14ecce37d2fb7769b1663" },
                });

                if (response.status === 200 && response.data?.Media) {
                    images = response.data.Media.map(media => ({
                        url: media.MediaURL,
                    }));
                }
            } catch (error) {
                console.error("Secondary API failed:", error.message);
            }
        }

        return { images };
    } catch (error) {
        console.error("Error fetching property media:", error.message);
        return { images: [] };
    }
}




async function fetchMedia(listingKey) {
    try {
        // const property = await fetchProperty(listingKey); // Uncomment if you need property data
        const mediaData = await fetchPropertyMedia(listingKey);

        const images = mediaData?.images || []; // Ensure images is always an array

        return { images };
    } catch (error) {
        console.error('Error in fetching property and media:', error.message);
        return { images: [] }; // Return an empty array instead of throwing an error
    }
}


export { getAccessToken, fetchProperty, fetchPropertyMedia, fetchMedia };
