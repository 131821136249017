import { useState } from "react";
import Logo from "./assets/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-[#BA1500] text-white">
      <div className="container mx-auto flex justify-between items-center px-4 py-3">
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-16 w-16 mr-4" />
          <div>
            <h1 className="text-3xl font-bold uppercase">About Yarcobr</h1>
            <p className="text-sm italic">A Jewel on A1A, Boca Raton</p>
          </div>
        </Link>

        <div className="hidden lg:block text-right">
          <p className="text-lg font-semibold">+1 561-702-3795</p>
          <p className="text-sm">Info@AboutYarcobr.com</p>
        </div>

        <button
          className="lg:hidden block text-2xl"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>
      </div>

      <nav
        className={`bg-[#d00d0e] transition-all duration-300 lg:flex lg:items-center lg:justify-center ${
          isMenuOpen ? "block" : "hidden"
        }`}
      >
        <ul className="flex flex-col lg:flex-row lg:gap-6 text-sm font-medium py-4 lg:py-2">
          <li>
            <Link to="/" className="hover:underline px-4 py-2 block">
              HOME
            </Link>
          </li>
          <li>
            <a href="#about-us" className="hover:underline px-4 py-2 block">
              ABOUT US
            </a>
          </li>
          <li>
            <a href="#eric-nur" className="hover:underline px-4 py-2 block">
              ERIC NUR
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:underline px-4 py-2 block">
              CONTACT US
            </a>
          </li>
          <li>
            <Link to="/mapsearch" className="hover:underline px-4 py-2 block">
              MAP SEARCH PAGE
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
